import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const LocalAuthorities = () => {
    return (
        <Layout>
            <Seo
      title="Autoridades Locales- BIKESAFE"
      description="Bike Safe ha trabajado con muchas autoridades locales para proporcionar soluciones de estacionamiento para bicicletas en el centro de las ciudades, centros comerciales, oficinas del Gobierno, aparcamientos de coches, estaciones de tren y autobús así como en centros educativos, con el fin de favorecer el uso de la bicicleta en ciudad."   />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Autoridades Locales
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Bike Safe ha trabajado con muchas autoridades locales para proporcionar soluciones de estacionamiento para bicicletas en el centro de las ciudades, centros comerciales, oficinas del Gobierno, aparcamientos de coches, estaciones de tren y autobús así como en centros educativos, con el fin de favorecer el uso de la bicicleta en ciudad.
                        </p>
                        <p className="flow-text">
                        El estacionamiento para bicicletas accesible fomenta el uso de bicicletas en ciudad, lo que reduce el tráfico y contribuye a un entorno más ecológico. Andar en bicicleta también es un hábito saludable.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/localauthoritypic.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"Su servicio fue sublime, recomendaría su empresa sin dudar"
                                <strong> Gina Harkell, responsable de ciclismo, London Borough de Waltham Forrest</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default LocalAuthorities;